import React, {Suspense} from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from "./components/home/home";
import Success from "./components/success";
const App = () => {
  return (
    <>
    

    <BrowserRouter>
    
  
    
     <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/done" element={<Success />} />
     </Routes>
     

    </BrowserRouter>
    
    </>
  )
}

export default App
