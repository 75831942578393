import React, { useState, useEffect } from 'react';
import "./home.css";
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Link } from 'react-router-dom';
import BgVideo from './video.mp4';
import {ClipLoader} from "react-spinners";
import {Fade} from "react-awesome-reveal";
const Home = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const videoElement = document.querySelector(".bg-vid");

    const handleLoadedData = () => {
      setIsLoading(false);
    };

    if (videoElement) {
      videoElement.addEventListener("loadeddata", handleLoadedData);
    }

    // Cleanup event listener on unmount
    return () => {
      if (videoElement) {
        videoElement.removeEventListener("loadeddata", handleLoadedData);
      }
    };
  }, []);

  return (
    <>
    
      {isLoading && (
        <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100vh", alignItems: "center", background:"linear-gradient(270deg,#0c0514,#052066)" }}>
          <ClipLoader color="#fff" />
        </div>
      )}
            
        <video autoPlay loop muted className="bg-vid">
        <source src={BgVideo} type="video/mp4" />
      </video>

      {!isLoading && (
      
        <div className="home">
          <div className="main">
            <div className="company_name">
              VENCH
            </div>
            <div className="launching_date">
              Launching On - 1 Dec'24
            </div>

            <div className="email_form">
              <form action="https://api.sheetmonkey.io/form/c1pVtzPcqSSUF36KjTC3xg" method="post">
                <input type="email" placeholder="Enter your mail address" required name="email" />
                <input type="hidden" name="Created" value="x-sheetmonkey-current-date-time" />
                <button value="send" type="submit" name="send">Subscribe to newsletter</button>
              </form>
            </div>

            <div className="socials_container">
              <div className="left_icons">
                <Link to="https://www.instagram.com/venchindia/" target='main'><InstagramIcon style={{color:'#999999'}} /></Link>
                <Link to="https://x.com/venchindia" target='main'><XIcon style={{color:'#999999'}} /></Link>
                <Link to="https://in.linkedin.com/company/vench-in" target='main'><LinkedInIcon style={{color:'#999999'}} /></Link>
              </div>
              <div className="right_text">
                Follow our handles
              </div>
            </div>
          </div>
        </div>
      

      )}
    </>
  );
}

export default Home;
