import React from 'react';
import './success.css';
import BgVideo from "./home/video.mp4";
const Success = () => {
  return (
    <>
     <video autoPlay loop muted className="bg-vid"> <source src={BgVideo} type="video/mp4" /> </video>
    <div className="success-container">
      <div className="success-text">
        You are now subscribed to our newsletter
      </div>
    </div>
    </>
  );
}

export default Success;
